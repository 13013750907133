import { Link } from "react-router-dom"
import { StyledText } from "../../styles/typography"
import { StyleFooter, StyleLinksFooter } from "./style"
import { ExternalRoutes } from "../../config/external-routes"


export const Footer = () => {
    return(
        <StyleFooter>
            <div>
                <p>
                <b>DGlic</b> © 2024.
                </p>
            </div>
            <div>
                <StyleLinksFooter>     
                    <StyledText text="height" color="--color-gray-100" lineHeight="18px" tag="p" className="">By:</StyledText>
                    <Link to={ExternalRoutes.COMPANY_PAGE} target="blank">
                        <StyledText text="six" color="--color-white" lineHeight="18px" tag="p" className=""> Bassena Dev</StyledText>
                    </Link>
                </StyleLinksFooter>
            </div>
        </StyleFooter>
    )
}