import React, { useContext } from 'react';
import { StyledContainerModal } from './style';
import { StyledButton } from '../button';
import { UserContext } from '../../contexts/userContext';

 
export const ModalExit = ({ onClose }) => {
    const { userLogout } = useContext(UserContext);

    const handleLogout = () => {
        userLogout(); // Chama a função userLogout para deslogar o usuário
        onClose(); // Fecha o modal após o logout
      };

  return (
    <StyledContainerModal>
        <section> 
            <div>
                <p>Deseja realmente sair?</p>
                <i>
                    <StyledButton type='button' buttonSize='small' buttonColor='gray' onClick={onClose}>
                        Cancelar
                    </StyledButton>
                    <StyledButton type='button' buttonSize="small" buttonColor="orange" onClick={handleLogout}>
                        Sair
                    </StyledButton>
                </i>
            </div>
        </section>  
    </StyledContainerModal>
  );
};
