import { Link } from "react-router-dom";
import { Header } from "../../../components/header";
import BasicFilterDemo from "../list-measure";
import 'primeicons/primeicons.css'
import { StyledNavAddRealod } from "../../corrections/correction/style";
import { StyleContainerListTitle, StyleContainerMaster, StyleContainerReload, StyledMeasureMain, StyledMeasurePage } from "./style";
import { FrontEndRoutes } from "../../../config/front-end-routes";
import { useState } from "react";
import { Footer } from "../../../components/footer";


export const Measure = () => {

    const [reloadKey, setReloadKey] = useState(0);

    const recarregarComponente = () => {
        setReloadKey(prevKey => prevKey + 1);
    };

    return (
        <StyleContainerMaster>
            <Header />
            <StyledMeasureMain>
                <StyledMeasurePage>
                    <StyleContainerListTitle>
                        <span>
                            <h3>Lista de medição</h3>
                        </span>
                        <StyledNavAddRealod>
                            <StyleContainerReload>
                                <Link to={FrontEndRoutes.AUTH.MEASUREMENTS.CREATE}>
                                    Adicionar &nbsp;
                                    <i className="pi pi-plus" style={{ fontSize: '1rem' }}></i>
                                </Link>
                            </StyleContainerReload>
                            <StyleContainerReload>
                                <button className="" onClick={recarregarComponente}>
                                    <i className="pi pi-sync" style={{ fontSize: '1rem' }}></i>
                                </button>
                            </StyleContainerReload>
                        </StyledNavAddRealod>
                    </StyleContainerListTitle>
                    <BasicFilterDemo key={reloadKey} />
                </StyledMeasurePage>
            </StyledMeasureMain>
            <Footer/>
        </StyleContainerMaster>
    );
}