import { StyleUserNav, StyledHeader, StyledHeaderUser, StyledLogoMob, StyledMeasureNav } from "./style"
import Logo from  '../../assets/imagens/Logo.png'
import { Container } from "../../styles/container"
import { AiOutlineMenu, AiOutlineCloseCircle } from 'react-icons/ai'
import { Link, useLocation } from "react-router-dom"
import {  useEffect, useState} from "react"
import { StyledText } from "../../styles/typography"
import { DropDownProfile } from "../dropDown"
import { api } from "../../services/api"
import {BackEndRoutes} from "../../config/back-end-routes";
import {FrontEndRoutes} from "../../config/front-end-routes";
import { useNavigate } from 'react-router-dom'
import {toast} from "react-toastify";
import { ModalOptions } from "../modal-options"
import { useMediaQuery } from "@react-hook/media-query"

export const Header = () => {

    const [userID, setUserID] = useState<any>([])
    const [userToken, ] = useState(localStorage.getItem("token"));
    const [openProfile, setOpenProfile] = useState(false);
    const [menuDropDown,setMenuDropDown] = useState(false)
    const [menuRegister,setMenuRegister] = useState(false)
    const [showModalOptions, setShowModalOptions] = useState(false);
    const isMobile = useMediaQuery("(max-width: 820px)");
    const navigate = useNavigate()

    useEffect(() => {
        fetchData();
    }, [userToken]);

    const location = useLocation();
    const fetchData = async () => {
        if (userToken) {
            let parametro = { token: userToken };
            try {
                await api.post(BackEndRoutes.routes.auth.AUTH, parametro).then((response) => {
                    if (response.status === 200) {
                        setUserID(response.data);
                    }
                }).catch((error) => {
                    console.log("Erro ao validar!");
                    console.log(error)
                    console.log(error.status)
                    if ( error?.response){
                        switch ( error?.response?.status ) {
                            case 401 :
                                // aqui deve redirecionar
                                toast.error("Sessão encerrada! Favor faça login novamente!")
                                localStorage.removeItem("token")
                                localStorage.removeItem("user")
                                navigate( FrontEndRoutes.LOGIN );
                                break;
                        }
                    }

                });


            } catch (error) {
                console.error("There was an error!", error);
            }
        } else {
          if (![FrontEndRoutes.REGISTER, FrontEndRoutes.RECOVER_PASSWORD, FrontEndRoutes.PASSWORD_RESET, FrontEndRoutes.LOGIN].includes(location.pathname)) {
            navigate(FrontEndRoutes.LOGIN);
        }
        }
    };

  const handleMenuDropDown = () => {
    setMenuDropDown(!menuDropDown)
    setMenuRegister(false)
  }
  const handleOptions = () => {
    setShowModalOptions(true); 
  };

  return(
    <StyledHeader>

      {userToken === null && 
      <Container>
        
        <img src={Logo} alt="Logo" />
        {
        menuDropDown ?
        <div>
          <button onClick={handleMenuDropDown}>
            <AiOutlineCloseCircle size={'30px'}/>
          </button>   
            <ul>
              <li>
                  <Link to={FrontEndRoutes.LOGIN}><StyledText text="five" color="--color-white" lineHeight="24px" tag="p" className="">Faça login</StyledText></Link>
              </li>
              <li>
                  <Link to={FrontEndRoutes.REGISTER}><StyledText text="five" color="--color-white" lineHeight="24px" tag="p" className="">Cadastro</StyledText></Link>
              </li>
            </ul>   
          </div>
          :

          <button onClick={handleMenuDropDown}>
            <AiOutlineMenu size={'30px'} color="white"/>
          </button> 
      
          }
        <div>
          <a href="#about">
            <StyledText text="seven" color="--color-white" lineHeight="24px" tag="p" className="">Sobre</StyledText>
          </a>
       
        </div>

        <div>
          <Link to={FrontEndRoutes.LOGIN}>
            <StyledText text="seven" color="--color-white" lineHeight="24px" tag="p" className="">Faça login</StyledText>
          </Link>
            <Link to={FrontEndRoutes.REGISTER}>
              <StyledText text="seven" color="--color-white" lineHeight="24px" tag="p" className="">Cadastre-se</StyledText>
            </Link>
        </div>
      
      </Container>
      }

      {userToken && 
      <StyledHeaderUser>
        <section>
        <StyledLogoMob>
          {isMobile && 
            <div onClick={handleOptions}>
              <i className="pi pi-bars" style={{ fontSize: '1rem' }}></i> 
            </div>
          }
          {showModalOptions && <ModalOptions onClose={() => setShowModalOptions(false)} />}
          <div>
            <Link to={FrontEndRoutes.AUTH.DASHBOARD.INDEX}>
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
        </StyledLogoMob>
          <StyledMeasureNav>
          <Link to={FrontEndRoutes.AUTH.DASHBOARD.INDEX}>
            <StyledText text="six" color="--color-white" lineHeight="24px" tag="p" className="">Home</StyledText>
          </Link>
          <Link to={FrontEndRoutes.AUTH.MEASUREMENTS.INDEX}>
            <StyledText text="six" color="--color-white" lineHeight="24px" tag="p" className="">Medições</StyledText>
          </Link>
          <Link to={FrontEndRoutes.AUTH.CORRECTIONS.INDEX}>
            <StyledText text="six" color="--color-white" lineHeight="24px" tag="p" className="">Correções</StyledText>
          </Link>
          </StyledMeasureNav>      
        </section>  
        <StyleUserNav onClick={() => setOpenProfile((prev) => !prev)}>
            {userID?.name?.split(" ")[0]}
            <i className="pi pi-angle-down" style={{ fontSize: '1rem' }}></i> 
        </StyleUserNav>
        {
          openProfile && <DropDownProfile/> 
        }
        
      </StyledHeaderUser>
      }

    
    </StyledHeader>
  )
}