import {useContext, useEffect, useState} from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import Logo from  '../../assets/imagens/Logo.png'
import { UserContext } from "../../contexts/userContext"
import { iLoginFormValues } from "../../contexts/userContext/@types"
import { SectionForm, StyleContLinkInstaAndFace, StyleDivPasswordLogin, StyleFooterLogin, StyledContainerLogin, StyledContainerPage, StyledConterTitle, StyledLoginPage } from "./style"
import { yupResolver } from "@hookform/resolvers/yup"
import { SchemaLogin } from "../../components/validators/schemaLogin"
import { Input } from "../../components/input"
import { StyledButton } from "../../components/button"
import { Header } from "../../components/header"
import {FrontEndRoutes} from "../../config/front-end-routes";
import { Footer } from "../../components/footer"
import { ExternalRoutes } from "../../config/external-routes"
import { StyledText } from "../../styles/typography"

export const Login = () => {
    const [showPasswordLogin, setShowPasswordLogin] = useState<boolean>(false);
    const {userLogin} = useContext(UserContext);
    const {register, handleSubmit, formState: {errors}, reset} = useForm<iLoginFormValues>({
        resolver: yupResolver(SchemaLogin), 
    })
    const submit: SubmitHandler<iLoginFormValues> = async (formData) => {
        try {
            await userLogin(formData);
            reset(); 
          } catch (error) {
            console.error('Erro ao registrar:', error);
          }
    }
    const togglePasswordVisibility = () => {
        setShowPasswordLogin((prevShowPassword) => !prevShowPassword); // Função para alternar a visibilidade da senha
      };
    return (
        <StyledLoginPage>
            <Header/>
            <StyledContainerPage>
                <StyledContainerLogin>
                        <StyledConterTitle>
                            <h2>Login</h2>
                        </StyledConterTitle>        
                    <SectionForm> 
                        <img src={Logo} alt="Logo" />
                        <form noValidate onSubmit={handleSubmit(submit)}>
                            <Input id='email' type='email' placeholder='Seu e-mail' {...register('email')}>E-mail</Input>
                            {errors.email && <span>{errors.email.message}</span>}
                            <StyleDivPasswordLogin>
                                <Input id='password' type={!showPasswordLogin ? 'password' : 'text'} placeholder='Sua senha' {...register('password')}>Senha</Input>
                                {errors.password && <span>{errors.password.message}</span>}
                                {showPasswordLogin ? <i onClick={togglePasswordVisibility} className="pi pi-eye-slash" style={{ fontSize: '1.5rem' }}></i>
                                : <i onClick={togglePasswordVisibility} className="pi pi-eye" style={{ fontSize: '1.5rem' }}></i>}
                            </StyleDivPasswordLogin>
                            <StyledButton  buttonColor='green' buttonSize='large' >Logar</StyledButton>
                            <Link to={FrontEndRoutes.RECOVER_PASSWORD}>Recuperar senha</Link>
                        </form>
                            <span>
                                    <Link to={FrontEndRoutes.REGISTER}>Registre-se</Link>

                                <StyleContLinkInstaAndFace>
                                    <Link to={ExternalRoutes.INSTAGRAM} target="blank">
                                        <i className="pi pi-instagram" style={{ fontSize: '2rem' }}></i>
                                    </Link> 
                                    <Link to={ExternalRoutes.FACEBOOK} target="blank">
                                        <i className="pi pi-facebook" style={{ fontSize: '2rem' }}></i>
                                    </Link>                                   
                                </StyleContLinkInstaAndFace>
                            </span>
                    </SectionForm>  
                </StyledContainerLogin>
            </StyledContainerPage>
                <StyleFooterLogin>     
                    <StyledText text="height" color="--color-gray-300" lineHeight="18px" tag="p" className="">By:</StyledText>
                    <Link to={ExternalRoutes.COMPANY_PAGE} target="blank">
                        <StyledText text="six" color="--color-gray-300" lineHeight="18px" tag="h3" className=""> Bassena Dev</StyledText>
                    </Link>
                </StyleFooterLogin>
        </StyledLoginPage>
    )
}