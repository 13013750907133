import axios from "axios";

export const api = axios.create({
  baseURL: 'https://dev.dglic.com.br/api',
  timeout: 100000,
  headers: {
    apiToken: localStorage.getItem('token') || ''
  }
});

api.interceptors.request.use(config => {
  config.headers['apiToken'] = localStorage.getItem('token') ?? null
  return config;
});