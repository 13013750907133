import React from 'react';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';


const PieChart = ({ data }) => {
    const total = data.reduce((acc, item) => acc + item.quant, 0);

    const categories = data.map(d => ({
        label: `${d.quant} - ${d.result.toUpperCase()}`,
        value: d.quant,
        color: d.color,
    }));

    const chartData = {
        labels: categories.map(c => c.label),
        datasets: [{
            data: categories.map(c => c.value),
            backgroundColor: categories.map(c => c.color),
            hoverBackgroundColor: categories.map(c => c.color)
        }]
    };

    const chartOptions = {
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true
                }
            }
        }
    };

    return (
        <>
            <Chart
                type="pie"
                data={chartData}
                options={chartOptions}
                className="w-full md:w-30rem"
            />

            <p className="m-0">
                <span className="text-bold text-lg">Total: {total}</span>
            </p>
        </>
    );
};

export default PieChart;
