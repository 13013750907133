import { createContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios';
import { iAddCorrection, iAddMeasure, iDefaultErrorApi, iPasswordReset, iRecoverPassword, iUpdateProfile } from './@types';
import { useNavigate } from 'react-router-dom'
import { api } from '../../services/api';
import { toast } from "react-toastify";
import {
  iDefaultProviderProps,
  iLoginFormValues,
  iRegisterFormValues,
  iUser,
  iUserContext,
  iUserLoginResponse,
} from './@types'
import {BackEndRoutes} from "../../config/back-end-routes";
import {FrontEndRoutes} from "../../config/front-end-routes";


export const UserContext = createContext({} as iUserContext)
export const UserProvider = ({ children }: iDefaultProviderProps) => {

  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<iUser | null>(null)
  const navigate = useNavigate()

  const userLogin = async (formData: iLoginFormValues) => {

    try {
      localStorage.removeItem('token');
      await api.post<iUserLoginResponse>(BackEndRoutes.routes.auth.LOGIN, formData).then( (response) =>{
        api.defaults.headers.authorization = `Bearer ${response.data.api_token}`

        if ( response.status === 200){
          toast.success('Login efetuado! Estamos redirecionando...')
          setLoading(true)
          localStorage?.setItem('token', response?.data?.api_token ?? null)
          navigate(FrontEndRoutes.DASHBOARD);
        }
      })

    } catch (err) {
      console.log(err);
      toast.error('Algo deu errado... Por favor, verifique seu email e senha para tentar novamente')
    }
  }


  const userRegister = async (formData: iRegisterFormValues) => {

    try {

      setLoading(true);
      const response = await api.post<iUser>(BackEndRoutes.routes.auth.REGISTER, formData)
      toast.success("Oba! Cadastro efetuado com sucesso!")

      navigate(FrontEndRoutes.LOGIN)

    } catch (error) {

      const defaultError = error as AxiosError<iDefaultErrorApi>

      switch (defaultError.response?.status) {
        case 400:
          toast.error("Este email já existe. Por favor, insira outro email")
          break;
        case 429:
          toast.error("Este email já existe. Por favor, insira outro email")
          break;
        default:
          toast.error("Algo deu errado. Por favor, verifique os campos e tente novamente")
          break;
      }
    } finally {

      setLoading(false)

    }
  }
  
  useEffect(() => {
    const loadUser = async () => {
      const token = localStorage.getItem("token")
      const userId = localStorage.getItem("user")
      
      if (!token || !userId) {
        return
      }
    }
    loadUser()
  }, [navigate])
  
  const userLogout = () => {
    setUser(null)
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    navigate( FrontEndRoutes.INDEX );
  }
  
  const recoverPassword = async (formData: iRecoverPassword) =>{
    try {
      await api.post<iRecoverPassword>(BackEndRoutes.routes.forget_password.REQUEST_EMAIL, formData).then( (response) =>{
        if ( response.status === 200){
          toast.success('Senha recuperada com sucesso...')
          setLoading(true)
          navigate( FrontEndRoutes.INDEX );
        }
      })

    } catch (err) {
      console.log(err);
      toast.error('Algo deu errado... Por favor, verifique seu email')
    }
  }

  const passwordReset = async (formData: iPasswordReset) =>{
    try {
      await api.post<iPasswordReset>(BackEndRoutes.routes.forget_password.PASSWORD_RESET, formData).then( (response) =>{
        if ( response.status === 200){
          toast.success('Senha alterada com sucesso...')
          setLoading(true)
          navigate( FrontEndRoutes.LOGIN );
        }
      })

    } catch (err) {
      console.log(err);
      toast.error('Algo deu errado... Por favor, verifique seu email')
    }
  }
  
  const updateProfile = async (formData: iUpdateProfile) => {
    try {
        const response = await api.post(BackEndRoutes.routes.authenticated.UPDATE_PROFILE, {...formData, token : localStorage.getItem('token')});
        
        if (response.status === 200) {
            toast.success('Dados atualizados com sucesso...');
            setLoading(true);
            navigate(FrontEndRoutes.INDEX);
        } else {
            toast.error('Falha ao atualizar dados do perfil');
        }
    } catch (err) {
        console.error('Erro ao atualizar dados do perfil:', err);
        toast.error('Algo deu errado...');
    }
};

const addMeasurements = async (formData: iAddMeasure) => {
  try {

    setLoading(true);
    const response = await api.post<iAddMeasure>(BackEndRoutes.routes.measurements.INDEX, formData)
    toast.success("Medição feita com sucesso!")
    navigate(FrontEndRoutes.AUTH.MEASUREMENTS.INDEX)


  } catch (error) {

    const defaultError = error as AxiosError<iDefaultErrorApi>
    switch (defaultError.response?.status) {
      case 400:
      default:
        toast.error("Algo deu errado. Por favor, verifique os campos e tente novamente")
        break;
    }
  } finally {

    setLoading(false)

  }
}

const addCorrections = async (formData: iAddCorrection) =>{
  try {
    await api.post<iAddCorrection>(BackEndRoutes.routes.corrections.INDEX, formData).then( (response) =>{
      if ( response.status === 200){
        toast.success('Correção feita com sucesso...')
        setLoading(true)
        navigate( FrontEndRoutes.AUTH.CORRECTIONS.INDEX );
      }
    })

  } catch (err) {
    console.log(err);
    toast.error('Algo deu errado... Por favor, verifique os campos')
  }
}

  return (
    <UserContext.Provider
      value={{ user, loading, userLogin, userRegister, userLogout, recoverPassword, passwordReset, updateProfile, addMeasurements, addCorrections }}
    >
      {children}
    </UserContext.Provider>
  )
}