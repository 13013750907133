import styled from "styled-components";

export const StyledContainerList = styled.main`
    span.p-tag-value{
        width:80px;
        text-align:center;
    }
    .p-paginator .p-dropdown .p-dropdown-label {
        display: flex;
        align-items: center;
        justify-content:center;
        color: var(--color-green-strong); 
        cursor: pointer;
    }
    .p-paginator .p-dropdown {
        margin-left: 3rem;
        height: 3rem;
        cursor: pointer;
    }
    .p-dropdown .p-dropdown-trigger {
        cursor: pointer;
    }
    svg.p-icon {
        cursor: pointer;
    }
    .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td  {
        border-bottom: solid 1px #D3D3D3;
    }
    i{
        cursor: pointer;
        margin-right:1rem;
    }
    `