import styled from "styled-components"

export const StyleFooter = styled.footer`
    position:absolute;
    bottom: 0;
    height:50px;
    width:100%;
    background: var(--color-green);
    color: var(--color-white);

    display:flex;
    align-items:center;
    justify-content:space-between;

    padding:1rem;
  
`
export const StyleLinksFooter = styled.div`
    
        display:flex;
        p:hover{
          color:lightgray;
        }
`