import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserContext } from '../../../contexts/userContext';
import { Input } from '../../../components/input';
import { StyledButton } from '../../../components/button';
import { FrontEndRoutes } from '../../../config/front-end-routes';
import { Dropdown } from 'primereact/dropdown';
import { toast } from 'react-toastify';
import { GeralServices } from '../../../services/GeralServices';
import { Header } from '../../../components/header';
import { useNavigate, useParams } from 'react-router-dom';
import { BackEndRoutes } from '../../../config/back-end-routes';
import { api } from '../../../services/api';
import { useLocation } from 'react-router-dom';
import {
  StyleContainer,
  StyleForm,
  StyleHeader,
  StyleContainerDaddy,
  StyledTxtArea,
  StyledCheckBox,
  StyledBoxButtons,
  StyledContainerDate,
  StyledContainerType,
  StyleConainerMasterCreate,
} from './style';
import { iAddMeasure } from '../../../contexts/userContext/@types';
import { Footer } from '../../../components/footer';

export const AddMeasure = () => {
  const { addMeasurements } = useContext(UserContext);

  const [selectedType, setSelectedType] = useState<any | null>(null);
  const [types, setTypes] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>(new Date().toISOString().split('T')[0]);
  const [selectedTime, setSelectedTime] = useState<any>(new Date().toLocaleTimeString('pt-BR').slice(0, 5));
  const [postContent, setPostContent] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [result, setResult] = useState('');
  const [isDisabled, setIsDisabled] = useState(true); 
  const [editingMeasurement, setEditingMeasurement] = useState<any | null>(null);
  const [ buscarDados, setBuscarDados] = useState(true)
  const location = useLocation();
  const isEditing = location.pathname.includes('/edit');
  const { id } = useParams<{ id: any }>();
  
  const navigate = useNavigate();
  const fetchMeasurement = () => {
    if (!id || !buscarDados) {
      return;
    }
    api.post(BackEndRoutes.routes.measurements.SHOW, { measurement_id: id })
      .then((response) => {
        if (response?.status === 200) {
          setBuscarDados(false);
          const measurementData = response?.data;
          setEditingMeasurement(measurementData);
          popularMedicao(measurementData);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error('Algo deu errado ao buscar a medição...');
      });
  };
  
  const fetchTypes = () => {
    api.get(BackEndRoutes.routes.types)
      .then((response) => {
        if (response.status === 200) {
          setTypes(response.data?.medicoes);
          fetchMeasurement();
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error('Algo deu errado...');
      });
  };
  
  
useEffect(() => {
  
  fetchTypes();
}, []);

const {
  register,
  handleSubmit,
  setValue,
} = useForm<iAddMeasure>();


const popularMedicao = (data) => {
  const { reference_date, type_id, measurement, observation } = data;

  // Extrair a data e a hora do reference_date
  const datePart = reference_date.split(' ')[0]; // Isso pega a parte da data '2024-05-01'
  const timePart = reference_date.split(' ')[1].slice(0, 5); // Isso pega a parte da hora '08:42'
  

  // Configurar os estados de data e hora
  setSelectedDate(datePart);
  setSelectedTime(timePart);

  const selectedTypeObject = types.find(type => type.id === type_id);
  setSelectedType(selectedTypeObject);

  setResult(calculateResult(measurement));
  setPostContent(observation);

  // Definir os valores dos campos do formulário usando setValue do react-hook-form
  setValue("reference_date", datePart);
  setValue("type_id", type_id);
  setValue("measurement", measurement);
  setValue("observation", observation);
};

const submit: SubmitHandler<any> = (formData) => {
  const dateTime = `${selectedDate} ${selectedTime}`;
  const typeID = selectedType ? selectedType.id : editingMeasurement?.type_id;
  const postData = {
    ...formData,
    measurement_id: editingMeasurement?.id ?? null,
    type_id: typeID,
    reference_date: dateTime ? GeralServices.formatDate(dateTime) : null,
    observation: postContent,
  };

  if (isEditing) {
    api.put(`${BackEndRoutes.routes.measurements.INDEX}`, postData)
      .then(() => {
        toast.success('Medição atualizada com sucesso!');
        navigate(FrontEndRoutes.AUTH.MEASUREMENTS.INDEX);
      })
      .catch((error) => {
        console.error('Erro ao salvar medição:', error);
        toast.error('Algo deu errado ao salvar a medição...');
      });
  } else {
    api.post(BackEndRoutes.routes.measurements.INDEX, postData)
      .then(() => {
        toast.success('Medição criada com sucesso!');
        navigate(FrontEndRoutes.AUTH.MEASUREMENTS.INDEX);
      })
      .catch((error) => {
        console.error('Erro ao salvar medição:', error);
        toast.error('Algo deu errado ao salvar a medição...');
      });
  }
};

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const calculateResult = (value: number) => {
    if (value < 70) {
      return 'BAIXO';
    } else if (value >= 70 && value < 120) {
      return 'NORMAL';
    } else {
      return 'ALTO';
    }
  };
  const handleMeasurementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const measurementValue = parseFloat(e.target.value);
    setResult(calculateResult(measurementValue));
  };
  
  const handleVoltar = () => {
    navigate( FrontEndRoutes.AUTH.MEASUREMENTS.INDEX );
  };

  return (
    <StyleConainerMasterCreate>
      <Header />
      <StyleContainerDaddy>
        <StyleContainer>
          <StyleHeader>
            <h2>{isEditing ? 'Edite a medição' : 'Crie uma nova medição'}</h2>
          </StyleHeader>
          <StyleForm noValidate onSubmit={handleSubmit(submit)}>
            <StyledContainerDate>
              <Input
                type='date'
                id="reference_date"
                defaultValue={isEditing ? editingMeasurement?.reference_date : selectedDate}
                {...register("reference_date")}
                onChange={date => setSelectedDate(date.target.value)}
              >
                Data
              </Input>
              <Input
                type='time'
                
                defaultValue={isEditing ? editingMeasurement?.reference_date.split(' ')[1].slice(0, 5): selectedTime}
                onChange={time => setSelectedTime(time.target.value)}
              >
                Hora
              </Input>
            </StyledContainerDate>
            <StyledContainerType>
              <p>Tipo de medição</p>
              <Dropdown
                 value={isEditing && editingMeasurement ? types.find(type => type.id === editingMeasurement.type_id) : selectedType}
                 id="id"
                 {...register("type_id")}
                 onChange={isEditing ? (e) => {
                  setSelectedType(e.target.value)
                  editingMeasurement.type_id = e.target.value?.id
                  } : (e) => {setSelectedType(e.target.value)}}
                 options={types}
                 optionLabel="description"
                 placeholder="Selecione o Tipo"
                 className="w-full md:w-14rem"
              />
            </StyledContainerType>
            <Input
              type="number"
              min={0}
              id="measure"
              placeholder="Medições"
              defaultValue={isEditing ? editingMeasurement?.measurement : ''}
              {...register("measurement")}
              onChange={handleMeasurementChange}
            >
              Medição
            </Input>
            <Input
              type="text"
              id="result"
              placeholder="Resultado"
              value={result}
              disabled={isDisabled} 
              className={isDisabled ? "disabled-input" : ""} 
            >
              Resultado
            </Input>
            <StyledTxtArea>
              Observação
              <textarea
                value={postContent}
                id='observation'
                {...register("observation")}
                name="postContent" rows={4}
                cols={100} placeholder='Digite uma observação'
                onChange={e => setPostContent(e.target.value)}
              />
            </StyledTxtArea>
            <StyledCheckBox>
              <input
                disabled
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              Existe correção?
              {isChecked && (
                <>
                  <input type="text" placeholder="Componente 1" />
                  <input type="text" placeholder="Componente 2" />
                </>
              )}
            </StyledCheckBox>
            <StyledBoxButtons>
              <StyledButton type='button' buttonSize='small' buttonColor='gray' onClick={handleVoltar}>
                Voltar
              </StyledButton>
              <StyledButton type="submit" buttonSize="small" buttonColor="green">
                {isEditing ? 'Atualizar' : 'Gravar'}
              </StyledButton>
            </StyledBoxButtons>
          </StyleForm>
        </StyleContainer>
      </StyleContainerDaddy>
      <Footer/>
    </StyleConainerMasterCreate>
  );
};