import { StyledDropDown } from "./style";
import { useState } from "react";
import { Link } from "react-router-dom";
import { StyledText } from "../../styles/typography";
import { FrontEndRoutes } from "../../config/front-end-routes";
import { Button } from 'primereact/button';
import { ModalExit } from "../modal-exit";


export const DropDownProfile = () => {
  const [showModalExit, setShowModalExit] = useState(false);
  
  const [showProfileAndLogout, setShowProfileAndLogout] = useState(false);

  const handleLogout = () => {
    setShowModalExit(true); 
  };

  const handleOptionsClick = () => {
    setShowProfileAndLogout(!showProfileAndLogout);
  };

  return (
    <div className="card">
      <StyledDropDown>
                  
          <div>
            <Link to={FrontEndRoutes.PROFILE}>
              <StyledText text="five" color="--color-white" lineHeight="24px" tag="p" className="">Perfil</StyledText>
            </Link>
            <Button onClick={handleLogout} className="p-button-warning" style={{ minWidth: '2rem' }} >
             <StyledText text="five" color="--color-white" lineHeight="24px" tag="p" className="">Sair</StyledText>
           </Button>
          </div>
       
          
        
      </StyledDropDown>
      {showModalExit && <ModalExit onClose={() => setShowModalExit(false)} />}
    </div>
  );
}
