import { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { api } from '../../../services/api';
import { BackEndRoutes } from '../../../config/back-end-routes';
import { StyledContainerListCorrection } from './style';
import { FrontEndRoutes } from '../../../config/front-end-routes';
import { useNavigate } from 'react-router';
import { Footer } from '../../../components/footer';


export default function CorrectionsComponent() {
    const [corrections, setCorrections] = useState(null);
    const navigate = useNavigate()
    const [filters, setFilters] = useState({

        reference_date: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        value: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        type: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

    });

    const [loading, setLoading] = useState(true);
    // const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [representatives] = useState([
        { name: 'Pré Almoço', image: 'amyelsner.png' },
        { name: 'Pós café da manhã', image: 'annafali.png' },
        { name: 'Hopoglicemia', image: 'asiyajavayant.png' },
        { name: 'Pós Jantar', image: 'bernardodominic.png' },
        { name: 'Outros', image: 'elwinsharvill.png' },
        { name: 'Pré Almoço', image: 'ionibowcher.png' },
        { name: 'Pós café da manhã', image: 'ivanmagalhaes.png' },
        { name: 'Hopoglicemia', image: 'onyamalimba.png' },
        { name: 'Pós Jantar', image: 'stephenshaw.png' },
        { name: 'Outros', image: 'xuxuefeng.png' }
    ]);
    const [statuses] = useState(['BAIXO', 'NORMAL', 'ALTA']);

    const getSeverity = (status: any) => {
        switch (status) {
            case 'BAIXO':
                return 'danger';

            case 'NORMAL':
                return 'success';

            case 'ALTA':
                return 'warning';

        }
    };

    const getCorrecntions = async () => {
        try {
            await api.get(BackEndRoutes.routes.corrections.INDEX).
                then((data) => {
                    setCorrections(data.data);
                    setLoading(false);
                });

        } catch (error) {
            console.error('Erro ao verificar e-mail:', error);
            return false;
        }
    }




    useEffect(() => {
        if (!corrections) {

            getCorrecntions()
        }

    }, []);

    const showCorrectionResult = (rowData: any) => {

        return <Tag value={rowData?.value} severity={getSeverity(rowData?.value)} />;
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };
    const handleEditClick = (id:number) => {  
        navigate(FrontEndRoutes.AUTH.CORRECTIONS.EDIT.replace(`:id`, String(id)));
    };

    return (
        <StyledContainerListCorrection>

            <div className="card">
                <DataTable
                    value={corrections ?? []}
                    showGridlines
                    tableStyle={{ minHeight: '30rem', minWidth: '50rem'  }}
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    stripedRows
                    paginator
                    rows={10}
                    dataKey="id"
                    filters={filters}
                    filterDisplay="row"
                    loading={loading}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Exibindo {first} de {last} total {totalRecords}"
                    emptyMessage="Não existem registros."
                    >
                    <Column
                        field="reference_date"
                        header="Data da Correção"
                        filter
                        filterPlaceholder="Pesquisar Data"
                        style={{ minWidth: '12rem' }}
                        body={(rowData) => formatDate(rowData.reference_date)}
                    />
                    <Column
                        header="Tipo de Correção"
                        field='type'
                        filterField="type"
                        style={{ minWidth: '12rem' }}
                        // body={countryBodyTemplate} 
                        filter
                        filterPlaceholder="Pesquisar Tipo"
                    />
                    <Column
                        //field="result" 
                        header="Correção"
                        showFilterMenu={false}
                        filterMenuStyle={{ width: '14rem' }}
                        style={{ minWidth: '12rem' }}
                        body={showCorrectionResult}
                        filter
                        filterPlaceholder="Pesquiser Correção"
                    />
                     <Column
                        body={(rowData) => (
                                <button onClick={() => handleEditClick(rowData.id)}>
                                    <i className="pi pi-file-edit" style={{ fontSize: '1.5rem' }}></i>
                                </button>                        
                        )}
                    />

                </DataTable>
            </div>
        </StyledContainerListCorrection>
    );
}
