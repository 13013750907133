import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import { iUpdateProfile } from "../../contexts/userContext/@types";
import {  StyleContainerDaddyProfile, StyleContainerMasterProfile, StyleContainerProfile, StyleForm, StyledBoxButtons, StyledHeaderProfile } from "./style";
import { Input } from "../../components/input";
import { StyledButton } from "../../components/button";
import { Header } from "../../components/header";
import { FrontEndRoutes } from "../../config/front-end-routes";
import { api } from "../../services/api";
import { BackEndRoutes } from "../../config/back-end-routes";
import { Footer } from "../../components/footer";

export const Profile = () => {
    const { updateProfile } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const emailParam = searchParams.get('email') || "";
    const { token } = useParams<{ token?: string }>();
    const [userData, setUserData] = useState<iUpdateProfile>({ name: '', email: emailParam, password: '', password_confirmation: '' });

    // useForm com defaultValues baseado no estado userData
    const { register, handleSubmit, formState: { errors }, reset } = useForm<iUpdateProfile>({
        defaultValues: userData
    });

    useEffect(() => {
        const fetchData = async () => {
            if (localStorage.getItem('token')) {
                try {
                    const response = await api.post(BackEndRoutes.routes.auth.AUTH, { token: localStorage.getItem('token') });
                    if (response.status === 200) {
                        setUserData(response.data);
                        reset(response.data); // Resetando o formulário com os novos valores
                    }
                } catch (error) {
                    console.error("Erro ao buscar dados do usuário:", error);
                }
            }
        };
        fetchData();
    }, [reset]);

    const submit: SubmitHandler<iUpdateProfile> = async (data) => {
        const submissionData: Partial<iUpdateProfile> & { token?: string } = { ...data, token };

        if (submissionData.password?.trim() === '') {
            delete submissionData.password;
        }
        if (submissionData.password_confirmation?.trim() === '') {
            delete submissionData.password_confirmation;
        }

        try {
            await updateProfile(submissionData as iUpdateProfile);
        } catch (error) {
            console.error("Erro ao atualizar perfil:", error);
        }
    };
    const navigate = useNavigate()
    const handleVoltar = () => {
      navigate( FrontEndRoutes.AUTH.DASHBOARD.INDEX );
    };

    return (
        <StyleContainerMasterProfile>
            <Header />
            <StyleContainerDaddyProfile>
                <StyleContainerProfile>
                    <StyledHeaderProfile>
                        <h2>Seu Perfil</h2>
                    </StyledHeaderProfile>
                    <StyleForm noValidate onSubmit={handleSubmit(submit)}>
                        <Input
                            type="text"
                            placeholder="Seu nome"
                            {...register("name")}
                            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                        >
                            Nome
                        </Input>

                        <Input
                            type="email"
                            placeholder="Seu E-mail"
                            {...register("email")}
                            onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        >
                            E-mail
                        </Input>
                        <Input
                            type="password"
                            placeholder="Digite sua senha"
                            {...register("password")}
                        >
                            Senha
                        </Input>
                        <Input
                            type="password"
                            placeholder="Confirme sua senha"
                            {...register("password_confirmation")}
                        >
                            Confirmação de Senha
                        </Input>

                        <StyledBoxButtons>
                            <StyledButton type='button' buttonSize='small' buttonColor='gray' onClick={handleVoltar}>
                                Voltar
                            </StyledButton>
                            <StyledButton type="submit" buttonSize="small" buttonColor="green">
                                Salvar
                            </StyledButton>
                        </StyledBoxButtons>
                    </StyleForm>
                   
                </StyleContainerProfile>
            </StyleContainerDaddyProfile>
            <Footer/>
        </StyleContainerMasterProfile>
    );
};
