import styled from "styled-components"

export const StyleContainerMaster = styled.main`
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    gap:5rem;
    
    header{
        width:100%;
    }
    @media(max-width: 451px){
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        gap:3rem;
    }
`
export const StyledMeasureMain = styled.main`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    padding:1rem; 
`

export const StyledMeasurePage = styled.section`
    width:100%;
    max-width:1200px;
    border: solid 1px var(--color-gray-600);
    padding: 1rem;
`

export const StyleContainerReload = styled.div`
    button{
        cursor: pointer;
    }
    i{
        cursor: pointer;
    }
`
export const StyleContainerListTitle = styled.section`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
    
       span{

           width:100%
       }
`