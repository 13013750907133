import { useContext, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { UserContext } from '../../contexts/userContext'
import { yupResolver } from '@hookform/resolvers/yup'
import { iRegisterFormValues } from '../../contexts/userContext/@types' 
import DiarioLogo from '../../assets/imagens/DiarioLogo.jpeg'
import Logo from '../../assets/imagens/Logo.png'
import { SchemaRegister } from '../../components/validators/schemaRegister'
import {IoIosArrowBack} from 'react-icons/io'
import {
  StyleContainer,
  StyleForm,
  StyleHeader,
  StyleLink,
  Link,
  LinkHome,
  StyleContainerLogo,
  IconAndHome,
  StyleContainerDaddy,
  ContainerFooter,
  StyleDivPassword,
} from './style'
import { Input } from '../../components/input'
import { StyledButton } from '../../components/button'
import { api } from '../../services/api'
import {FrontEndRoutes} from "../../config/front-end-routes";


export const RegisterUser = () => {
  const { userRegister } = useContext(UserContext)
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<iRegisterFormValues>({
    resolver: yupResolver(SchemaRegister),
  })

  const submit: SubmitHandler<iRegisterFormValues> = async (formData) => {
    try {
      await userRegister(formData);
      reset(); 
    } catch (error) {
      console.error('Erro ao registrar:', error);
    }
  }

  async function checkEmailExists(email: string): Promise<boolean> {
   
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(regex.test(email)) {
   
      try {
        const response = await api.post('/emailRegister', {email: email});
        const data = await response.data;
        return data?.email; 
        
      } catch (error) {
          console.error('Erro ao verificar e-mail:', error);
          return false;
      }
        
    } 
    return false
      
}
  const [emailExists, setEmailExists] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);


  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(async () => {
        if (value) {
          const exists = await checkEmailExists(value);
          setEmailExists(exists);
        }
      }, 500) // Tempo de espera após a última digitação (em milissegundos)
    );
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword); // Função para alternar a visibilidade da senha
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword); // Função para alternar a visibilidade da senha
  };
  
  return (
    <div>
      <StyleContainerDaddy>
        <StyleContainer>
          <figure>
            <img src={Logo} alt="" />
          </figure>

          <IconAndHome>
              <IoIosArrowBack />
              <LinkHome to={FrontEndRoutes.INDEX}>Voltar</LinkHome>
          </IconAndHome>
        
          <StyleHeader>
            <h2>Crie sua conta</h2>
            <p>Preencha os seus dados abaixo</p>
          </StyleHeader>

          <StyleForm  noValidate onSubmit={handleSubmit(submit)}>
            <Input
              type="text"
              id="name"
              placeholder="Seu nome completo"
              {...register("name")}
            >
              Nome completo
            </Input>
            {errors.name && <span>{errors.name.message}</span>}
            <Input
              type="email"
              id="email"
              placeholder="Seu E-mail"
              {...register("email")}
              onChange={handleEmailChange}
            >
              E-mail 
            </Input>
            {emailExists && <p style={{ color: 'red' }}>Este e-mail já está cadastrado!
            <Link to={ FrontEndRoutes.LOGIN }> Faça seu login aqui.</Link></p>}
            {errors.email && <span>{errors.email.message}</span>}
            <StyleDivPassword>
              <Input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="Digite sua senha"
                {...register("password")}
              >
                Senha
              </Input>
                {showPassword ? <i onClick={togglePasswordVisibility} className="pi pi-eye-slash" style={{ fontSize: '1.5rem' }}></i>
                 : <i onClick={togglePasswordVisibility} className="pi pi-eye" style={{ fontSize: '1.5rem' }}></i>}
            </StyleDivPassword>
                 {errors.password && <span>{errors.password.message}</span>}
            <StyleDivPassword>
              <Input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                placeholder="Confirme sua senha"
                {...register("confirmPassword")}
              >
                Confirme Senha
              </Input>
                  {showConfirmPassword ? <i onClick={toggleConfirmPasswordVisibility} className="pi pi-eye-slash"  style={{ fontSize: '1.5rem' }}></i>
                   : <i onClick={toggleConfirmPasswordVisibility} className="pi pi-eye" style={{ fontSize: '1.5rem' }}></i>}                
            </StyleDivPassword>
              {errors.confirmPassword && (
                <span>{errors.confirmPassword.message}</span>
              )}

            <StyledButton type="submit" buttonSize="large" buttonColor="green">
              Registre-se
            </StyledButton>
          </StyleForm>
          <StyleLink>
            <p>
               <Link to={FrontEndRoutes.LOGIN}>Já sou membro</Link>
            </p>
          </StyleLink>
        </StyleContainer>
        <StyleContainerLogo>
              <img src={DiarioLogo} alt="" />
        </StyleContainerLogo>  
      </StyleContainerDaddy>
      <ContainerFooter>
      </ContainerFooter>
    </div>
  )
}
