import styled from "styled-components"

export const StyledLoginPage = styled.main`
    display:flex;
    flex-direction:column;
    gap: 1rem;
    height:100vh;
    width:100vw;
  
    h2{
        color:var(--color-white)
    }
    span{
        display:flex;
        align-items:center;
        flex-direction:column;
        padding-top:1rem;
        gap: 1rem;
    }
`
export const StyledContainerPage = styled.section`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    gap: 3rem;
  
    padding: 8px; 
    
`
export const StyledContainerLogin = styled.section` 
    width:100%;
    max-width:420px;
    padding-bottom:1rem;
    box-shadow: 0px 4px 4px var(--color-gray-600);
    border-radius: 8px 8px 8px 8px;

`
export const SectionForm = styled.section`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    padding:1rem;

    width:100%;
    max-width:420px;
    height:560px;
   
    
    img{
        width:133px;
    }
    
    form{
        display:flex;
        flex-direction:column;
        align-items:center;
        
        gap: 1.5rem;
        width:100%;
       
        & input{
            width:100%;
            height: 60px;

            border: solid 1px var(--color-green);
            border-radius:4px;

            padding-left:8px;
        }
        & label{
            color: var(--color-gray-300);

            align-items:flex-start;
        }
        & button{
            background-color: var(--color-green);
            color:var(--color-white);

            width:100%;
            height:50px;
            max-width:420px;

            border-radius:4px;
        }
        & span{
            font-size: var(--font-size-8);
            color: var(--color-error);
        }
      
    }
    @media(min-width: 821px){
        height:550px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
    }
    
`
export const StyledConterTitle = styled.div`
    
        display:flex;
        align-items:center;
        justify-content:center;

        width:100%;
        max-width:420px;
        height:50px;

        background-color:var(--color-green);
        border-radius: 8px 8px 0px 0px;   
`
export const StyleContLinkInstaAndFace = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-around;
    width: 100%;
    color: var(--color-gray-300);
    i:hover {
        cursor: pointer;
        color: var(--color-green)
    }
`
     
export const StyleFooterLogin = styled.footer`
   
    height:40px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:end;
   
    padding:1rem;
    
    h3:hover{
        color:var(--color-green);
    }
`
export const StyleDivPasswordLogin = styled.div`
  position: relative;
  display:flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  
  i{
    cursor: pointer;
    
    position:absolute;
    right:5%;
    bottom: 15px;
    
  }
`