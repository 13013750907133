import styled from "styled-components";


export const StyledDropDown = styled.section`
  display: flex;
  flex-direction: column;

  position: absolute;
  top:5rem;
  right: 1.5rem;
  background-color: var(--color-green);

  width: 130px;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid var(--color-gray-100); 
  z-index: 999;

  .button-opitons{
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
    color: var(--color-white);
    text-align: center;
  }
`
