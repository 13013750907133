import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { api } from '../../../services/api';
import { BackEndRoutes } from '../../../config/back-end-routes';
import { StyledContainerList } from './style';
import { FrontEndRoutes } from '../../../config/front-end-routes';
import { Link, Navigate, useNavigate } from 'react-router-dom';

export default function BasicFilterDemo() {
    const [measurements, setMeasurements] = useState(null);
    const [filters, setFilters] = useState({

        reference_date: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        measurement: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        observation: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        result: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        type: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

    });

    const [loading, setLoading] = useState(true); 
    const navigate = useNavigate(); 
    // const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [representatives] = useState([
        { name: 'Pré Almoço', image: 'amyelsner.png' },
        { name: 'Pós café da manhã', image: 'annafali.png' },
        { name: 'Hopoglicemia', image: 'asiyajavayant.png' },
        { name: 'Pós Jantar', image: 'bernardodominic.png' },
        { name: 'Outros', image: 'elwinsharvill.png' },
        { name: 'Pré Almoço', image: 'ionibowcher.png' },
        { name: 'Pós café da manhã', image: 'ivanmagalhaes.png' },
        { name: 'Hopoglicemia', image: 'onyamalimba.png' },
        { name: 'Pós Jantar', image: 'stephenshaw.png' },
        { name: 'Outros', image: 'xuxuefeng.png' }
    ]);
    const [statuses] = useState(['BAIXA', 'NORMAL', 'ALTA']);

    const getSeverity = (status: any) => {
        switch (status) {
            case 'BAIXA':
                return 'danger';

            case 'NORMAL':
                return 'success';

            case 'ALTA':
                return 'warning';
        }
    };

    const getMeasurements = async () => {
        try {
            await api.get(BackEndRoutes.routes.measurements.INDEX)
                .then((data) => {
                    setMeasurements(data.data);
                    setLoading(false);
                });

        } catch (error) {
            console.error('Erro ao verificar e-mail:', error);
            return false;
        }
    }

    useEffect(() => {
        if (!measurements) {

            getMeasurements()
        }

    }, []);

    const showMeasureResult = (rowData: any) => {

        return <Tag value={rowData?.result} severity={getSeverity(rowData?.result)} />;
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };
    const handleEditClick = (id:number) => {
        
        navigate(FrontEndRoutes.AUTH.MEASUREMENTS.EDIT.replace(`:id`, String(id)));
    };

    return (
        <StyledContainerList>
            <div className="card" >
                <DataTable
                    value={measurements ?? []}
                    showGridlines
                    tableStyle={{ minHeight: '33rem', minWidth: '50rem'  }}
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    stripedRows
                    paginator
                    rows={10}
                    dataKey="id"
                    filters={filters}
                    filterDisplay="row"
                    loading={loading}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Exibindo {first} de {last} total {totalRecords}"
                    emptyMessage="Não existem registros."
                    >
                    <Column
                        field="reference_date"
                        header="Data da Medição"
                        filter
                        filterPlaceholder="Pesquisar Data"
                        style={{ minWidth: '12rem' }}
                        body={(rowData) => formatDate(rowData.reference_date)}

                    />
                    <Column
                        header="Tipo de Medição"
                        field='type'
                        filterField="type"
                        style={{ minWidth: '12rem' }}
                        filter
                        filterPlaceholder="Pesquisar Tipo"
                    />
                    <Column
                        field="measurement"
                        header="Medição"
                        filterField="measurement"
                        showFilterMenu={false}
                        filterMenuStyle={{ width: '14rem' }}
                        style={{ minWidth: '14rem' }}
                        filterPlaceholder="Pesquisar Medição"
                        filter
                    />
                    <Column
                        header="Resuldado"
                        showFilterMenu={false}
                        filterMenuStyle={{ width: '14rem' }}
                        style={{ minWidth: '12rem' }}
                        body={showMeasureResult}
                        filter
                        filterPlaceholder="Pesquiser Resultado"                      
                    />
                     <Column
                        body={(rowData) => (
                                <button onClick={() => handleEditClick(rowData.id)}>
                                    <i className="pi pi-file-edit" style={{ fontSize: '1.5rem' }}></i>
                                </button>                        
                        )}
                    />
                </DataTable>
            </div>
        </StyledContainerList>
    );
}
