export const FrontEndRoutes = {
    INDEX : '/',
    LADING_PAGE : '/landing',
    LOGIN : '/login',
    AUTH: {
        DASHBOARD : {
            INDEX: '/dashboard'
        },
        MEASUREMENTS : {
            INDEX : '/measurements',
            CREATE : '/measurements/create',
            EDIT : '/measurements/edit/:id',
        },
        CORRECTIONS : {
            INDEX : '/corrections',
            CREATE : '/corrections/create',
            EDIT : '/corrections/edit/:id',
        }
    },
    DASHBOARD : "/dashboard",
    REGISTER: '/register',
    RECOVER_PASSWORD: '/recover/password',
    PASSWORD_RESET: "/password/reset/:token",
    PROFILE: "/profile",
}