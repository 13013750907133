

const isDev:boolean =  process.env.NODE_ENV !== "production";
export const BackEndRoutes = {
  getHost: (): string => {
    const urlEnvDev:string = process.env?.VITE_API_LOCAL_URL ?? ""; //"http://dev.dglic.com.br/"
    const urlEnvProd:string =  process.env?.VITE_API_PRODUCTION_URL ?? "";//"http://dglic.com.br/"

    const urlDev =
      urlEnvDev.length > 0
        ? urlEnvDev
        : "http://127.0.0.1:8000/api/v1";

    const urlProd =
      urlEnvProd.length > 0
        ? urlEnvProd
        : "https://dglic.com.br/api";

    const url = isDev ? urlDev : urlProd;

    return url;
  },
  routes: {
    auth: {
      LOGIN: "/login",
      REGISTER: "/register",
      AUTH: '/authUser',
      VERIFY: "/auth/verify",
      LOGOUT: "/auth/logout",
    },
    types : "/tipos",
    forget_password: {
      REQUEST_EMAIL :"/password/email",
      PASSWORD_RESET: "/password/reset"
    },
    authenticated: {
      UPDATE_PROFILE: "/updateUser",
      PROFILE: {},
      PERMISSION: {},
    },
    measurements : {
      INDEX: "/measurements",
      ADD: "/add/measurement",
      SHOW:  "/measurement",
      
    },
    corrections : {
      INDEX: "/corrections",
      ADD: "/add/measurement",
      SHOW: "/correction/:id",
    },

    dashboard : {
      GERAL : '/medicoes'
    }



  },
};