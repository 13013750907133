import styled from "styled-components";

export const StyledContainerModal = styled.main`
        position: fixed;
        inset: 0;
        top: 0;
        right: 0;
        width:80%;
        max-width:300px;
        height: 100vh;

        background-color: rgba(0, 0, 0, 0.5);

        display: flex;
        flex-direction: column;
        
        
        z-index:99;
    
`
export const StyleContainerDad = styled.section `

        display: flex;
        flex-direction:column;
        background: white;
        height:100%;
        width:99%;
        padding: 1rem;
        
       
        div{
            width:100%;
            padding: 4px;
            display:flex;
            align-items:center;
            justify-content:space-between;
            div{
                display:flex;
                flex-direction:column;
                align-items:start;
            }
        }

    
`
export const StyleNavContainerOption = styled.nav`
    display: flex;
    flex-direction: column;
    margin-top: 0.8rem;
    gap: 1rem;
    height:100%;
    width:100%;
    div{
        display:flex;
        align-items: center;
        justify-content:start;
        gap: 2rem;
        
    }
    button{
        display:flex;
        align-items:center;
        gap:8px;
    }
    P:hover{
        color:var(--color-green)
    }
    i{
        cursor: pointer;
        color:var(--color-green)

    }
    i:hover{
        color:var(--color-green)
    }
    & span{
            display:flex;
            flex-direction:column;
            justify-content:start;
            padding-top:1px;
        }
`

export const StyleContHeaderOptions = styled.div`
    width:99%;
    padding: 1.5rem;

    display:flex;
    align-items:center;
    justify-content:center;

    background-color: var(--color-green);
`

  export const StyleFooterOptions = styled.div `
  height:43px;
  width:99%;
  background-color:var(--color-green);
  
  display:flex;
  align-items:center;
  justify-content:center;
  `