import {useContext} from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { UserContext } from "../../contexts/userContext"
import { iRecoverPassword } from "../../contexts/userContext/@types"
import { SectionForm, StyleContainerMasterRecover, StyledRecoverPassword } from "./style"
import { yupResolver } from "@hookform/resolvers/yup"
import { Input } from "../../components/input"
import { StyledButton } from "../../components/button"
import { Header } from "../../components/header"
import { SchemaRecover } from "../../components/validators/schemaRecover"
import {FrontEndRoutes} from "../../config/front-end-routes";
import { Footer } from "../../components/footer"

export const RecoverPassword = () => {
    const {recoverPassword} = useContext(UserContext)
    const {register, handleSubmit, formState: {errors}, reset} = useForm<iRecoverPassword>({
        resolver: yupResolver(SchemaRecover), 
    })
    const submit: SubmitHandler<iRecoverPassword> = async (formData) => {
        await recoverPassword(formData)
        reset()
    }
    return (
        <StyleContainerMasterRecover>
            <Header/>
            <StyledRecoverPassword>
                    <SectionForm> 
                        <div>
                            <h2>Esqueceu sua senha?</h2>
                        </div>        
                        <p >Digite o e-mail que deseja recuperar a conta!</p>
                        <form noValidate onSubmit={handleSubmit(submit)}>
                            <Input id='email' type='email' placeholder='Seu e-mail' {...register('email')}>E-mail</Input>
                            {errors.email && <span>{errors.email.message}</span>}
                            <StyledButton  buttonColor='green' buttonSize='large'>Recuperar Senha</StyledButton>
                        </form>
                        <section>
                            <span>
                                <Link to={FrontEndRoutes.REGISTER}>Registre-se aqui</Link>
                                <Link to={FrontEndRoutes.INDEX}>{`< Voltar`} </Link>
                            </span>
                        </section>
                    </SectionForm>        
            </StyledRecoverPassword>
            <Footer/>
        </StyleContainerMasterRecover>
    )
}