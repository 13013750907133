import {useContext, useState} from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { UserContext } from "../../contexts/userContext"
import { iPasswordReset } from "../../contexts/userContext/@types"
import { SectionForm, StyleContainerMasterResetPassword, StyledPasswordReset } from "./style"
import { yupResolver } from "@hookform/resolvers/yup"
import { Input } from "../../components/input"
import { StyledButton } from "../../components/button"
import { Header } from "../../components/header"
import { SchemaPasswordReset } from "../../components/validators/schemaPasswordReset"
import {FrontEndRoutes} from "../../config/front-end-routes";
import { Footer } from "../../components/footer"



export const PasswordReset = () => {
    const { passwordReset } = useContext(UserContext);
    const { register, handleSubmit, formState: { errors }, reset } = useForm<iPasswordReset>({
        resolver: yupResolver(SchemaPasswordReset),
    });
    const submit: SubmitHandler<iPasswordReset> = async (formData) => {
        await passwordReset({...formData, token});
        reset();
    };

    const [searchParams] = useSearchParams();
    const sEmail = searchParams.get('email') || "";
    const { token } = useParams<{ token?: string }>(); // Ajusta a tipagem correta para useParams

    const [email,] = useState<string | any>(sEmail); // Usa o valor obtido dos parâmetros de busca

    return (
        <StyleContainerMasterResetPassword>
            <Header/>
            <StyledPasswordReset>
                <SectionForm> 
                        <div>
                            <h2>Redefinir Senha</h2>
                        </div>        
                        <p >Insira sua nova senha.</p>
                        <form noValidate onSubmit={handleSubmit(submit)}>
                            <Input
                            type="email"
                            id="email"
                            placeholder="Seu E-mail"
                            value={email}
                            readOnly
                            {...register("email")}
                            >
                            E-mail
                            </Input>
                            {errors.email && <span>{errors.email.message}</span>}
                            <Input
                            type="password"
                            id="password"
                            placeholder="Digite sua senha"
                            {...register("password")}
                            >
                            Senha
                            </Input>
                            {errors.password && <span>{errors.password.message}</span>}
                            <Input
                            type="password"
                            id="password_confirmation"
                            placeholder="Confirme sua senha"
                            {...register("password_confirmation")}
                            >
                            Senha
                            </Input>
                            {errors.password_confirmation && (
                            <span>{errors.password_confirmation.message}</span>
                            )}

                            <StyledButton type="submit" buttonSize="large" buttonColor="green">
                            Alterar senha
                            </StyledButton>
                        </form>
                        <section>
                            <span>
                                <Link to={FrontEndRoutes.REGISTER}>Registre-se aqui</Link>
                            </span>
                                <Link to={FrontEndRoutes.INDEX}>{`< Voltar`} </Link>      
                        </section>
                </SectionForm>        
            </StyledPasswordReset>
            <Footer/>
        </StyleContainerMasterResetPassword>
    )
}