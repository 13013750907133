import { createGlobalStyle } from 'styled-components';
import "react-toastify/dist/ReactToastify.css";

export const Global = createGlobalStyle`
:root{
  --color-beige: #f2f2f2;
  --color-blue: #00a19a;
  --color-green-shadow: rgba(154, 255, 217, .1); 
  --color-white: #c5d7e1;
  --color-blue-strong: #2c73eb;
  --color-green: #38c172;
  --color-green-strong:#006400;
  --color-orange: #eb5757;
  --color-purple: #a5a4bf;
  --color-white: #ffffff;


  --color-gray-0: #f2f2f2;
  --color-gray-100: #e0e0e0;
  --color-gray-300: #828282;
  --color-gray-350: #545b62;
  --color-gray-600: #333333;
  --color-gray-700: #212529;
  --color-gray-800: #000000;

  --color-error: #e60000;
  --color-sucess: #168821;
  --color-warning: #ffcd07;

  --font-size-1: 3rem;
  --font-size-2: 2.5rem;
  --font-size-3: 2rem;
  --font-size-4: 1.375rem;
  --font-size-5: 1.125rem;
  --font-size-6: 1rem;
  --font-size-7: 0.875rem;
  --font-size-8: 0.75rem;

  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;

  --border-radius-1: 8px;
  --border-radius-2: 5px;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  cursor: default;
}

body{
  font-family: 'Inter', sans-serif;
}

button{
  cursor: pointer;
  border: none;
  background: transparent;
}

a{
  color: unset;
  text-decoration: none;
  cursor: pointer;
}

ul, ol, li{
  list-style: none;
  cursor: pointer;
}
`