import * as yup from 'yup'

export const SchemaRegister = yup.object().shape({
    name:yup.string().required("Este campo é obrigatório"),
    email: yup.string().required("O email é obrigatório").email("Email inválido"),
    password:yup.string().required("A senha é obrigatória"),
    confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Senhas não coincidem")
    .required("Confirme sua senha")
})