import styled from "styled-components";


export const StyledHeader = styled.header`
 
  box-shadow: 0px 1px 20px var(--color-green);
  background-color: var(--color-green);


  padding: 34px;

  font-size: var(--font-size-6);
  font-weight: var(--font-weight-regular);
  font-family: Arial, Helvetica, sans-serif;
  
  ul{
    position: absolute;
    top: 67px;
    right: 0;

    border: none;

    box-shadow: 0px 4px 4px var(--color-green);

    background-color: var(--color-green);

  }
  
  li{
    width: 191px;
    padding: 15px 22px;
    
    text-align: right;
    transition: 0.5s;
    
  }

  li:hover{
    background-color: var(--color-green);
    & p {
      color: var(--color-gray-600);
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 33px;
  }

  & > div > img, & > div > a > img{
    width: 120px;
  }

  & > div > div:nth-child(2){
    position: relative;
    height: 33px;
  }

  & > div > div:nth-child(3),& > div > div:nth-child(4){
    display: none;
  }
 
  @media(min-width: 820px){
    & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 33px;
  }
    
    & > div button{
      display: none;
     
    }

    & > div > div:nth-child(2){
    display: none;
 }

    & > div > div:nth-child(3){
    display: flex;
    gap: 40px; 
    margin-left:30px;
    
    
  }
  & > div > div:nth-child(4){
    display:flex;
    gap: 40px; 
    position:absolute;
    right: 100px;
  }

}
`
export const StyledHeaderUser = styled.header`
  display:flex;
  align-items:center;
  justify-content:space-between;

  section{
    display:flex;
    gap: 1rem;
    align-items:center;
    justify-content:space-between;
  }

 
  height:33px;

  img{
    width: 120px;
    cursor: pointer;
  }
  i{
    cursor: pointer;
    color: var(--color-gray-100)
  }
 
`
export const StyledLogoMob = styled.section`
           
`
export const StyleUserNav = styled.nav`
    display: flex;
    justify-content:center;
    align-items:center;
    gap:4px;

    position: absolute;
    right: 30px;

    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
    color: var(--color-white);
  
    padding: 15px;
    border-radius: 15px;
    border: 1px solid var(--color-gray-100);
`
  export const StyledMeasureNav = styled.div`
    
   
      display:none;
      align-items:center;
      justify-content:space-between;
      gap: 1rem;
      

   @media(min-width: 820px){

    display:flex;
    align-items:center;
    justify-content:space-between;
    gap: 1rem;
  }

  `
  

