import styled from "styled-components";

export const StyleDashContainer = styled.main`
    display:flex;
    flex-direction:column;
    height:100vh;
    width:100vw;
  
    h2{
        color:var(--color-white)
    }
    span{
        display:flex;
        align-items:center;
        flex-direction:column;
        padding-top:1rem;
        gap: 1rem;
    }
`
export const StyleContainerInfo = styled.section`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    gap: 3rem;
  
    padding: 8px; 
    
`
export const DashContainer = styled.div`
  width: min(80%, 1400px);
  margin-inline: auto;
  height:100vh;

  display:flex;
  align-items:start;
  justify-content:start;
  padding-top:4rem;
`
export const StyleContainerPie = styled.div`
    
    width:35%;
    height: 500px;
    border-radius: 8px;
    box-shadow: 4px 4px 4px 4px var(--color-gray-300);
    

`
export const StyleContainerDateInf = styled.div`
    width:65%;
    height: 500px;
    border-radius: 8px;
    box-shadow: 4px 4px 4px 4px var(--color-gray-300);
`

