import styled from "styled-components";

export const StyleContainerMaster = styled.main`
    display:flex;
    flex-direction:column;
    gap: 1rem;
    height:100vh;
    width:100vw;
  
`
export const StyleContainer = styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
  
    padding: 8px;
`;

export const StyleForm = styled.form`
        display:flex;
        flex-direction:column;
        align-items:center;
        padding:1rem;
        
        gap: 2rem;
        width:100%;
        
  input{
    border: 1px solid var(--color-green);
  }
  input:focus{
    
    border: 2px solid var(--color-green-strong);
  }
  & input::placeholder{
    color: var(--color-green-strong);
  }
  & span {
    font-size: var(--font-size-8);
    color: var(--color-error);
  }
  @media (min-width: 820px) {
    max-width: 500px;
  }
  `;

export const StyledTxtArea = styled.label`
  display:flex;
  flex-direction:column;
  gap:0.5rem;
  box-sizing:border-box;
  
  textarea{
    border:solid 1px var(--color-green);
    border-radius: 8px;
    width: 100%;
    height: 80px;;
    padding: 6px;
    outline: 0;
  }
  & textarea::placeholder{
    color: var(--color-green-strong);
  }
  textarea:focus{
    border: 2px solid var(--color-green-strong);
  }
`

export const StyledBoxButtons = styled.div`
  display: flex;
  width:100%;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  & button {
    margin-top: 5%;
    width: 782.21px;
    max-width: 100%;
  }
  
  
`
export const StyledContainerDate = styled.section`
  display:flex;
  width:100%;
  align-items: center;
  gap: 0.7rem;
  
    input {
      text-align: center;
      border: none;
      background-color: transparent;
      border: 1px solid var(--color-green);
      padding: 1rem;
    &:focus {
      outline: none;
    }
    input:focus {
      outline: none;
  }
}
`
export const StyledContainerType = styled.section`
    width:100%;
    max-width:700px; 
    display:flex;
    flex-direction:column;
    gap:1rem; 
    
  div{
    width: 100%;
    max-width:700px;
    display:flex;
    justify-content:space-between;
    border: 1px solid var(--color-green);
    box-shadow: 0px 0px 8px 10px var(--color-green-shadow);
    outline: 0;
    input:focus{
      border: 2px solid var(--color-green-strong);
      
    }
    
    & div{
      width: 100%;
      max-width:40px;
      background-color: var(--color-green);
      display:flex;
      align-items:center;
      justify-content:center;
      color: var(--color-white);
      
    }
  } 
  span{
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-7);
    line-height: 19px;
    color: var(--color-green-strong);
    padding:1rem;
    padding-right: 2rem;
    
  }
`
export const StyledConterTitleCreate = styled.div`
    
        display:flex;
        align-items:center;
        justify-content:center;
        color: var(--color-white);

        width:100%;
        max-width:500px;
        height:50px;

        background-color:var(--color-green);
        border-radius: 4px 4px 0px 0px;
    
`
export const StyledContainerCreateCorrection = styled.section`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    gap:2rem;
    width:100%;
    max-width:420px;
    box-shadow: 0px 4px 4px var(--color-gray-600);
    border-radius: 8px 8px 4px 4px;
    
`