import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Header } from "../../components/header";
import { api } from '../../services/api';
import PieChart from "../../components/dashboard/chart/pie";
import LinesChart from "../../components/dashboard/chart/lines";
import { StyleContainerInfo, StyleDashContainer, DashContainer, StyleContainerPie, StyleContainerDateInf } from './style';
import { BackEndRoutes } from "../../config/back-end-routes";
import { toast } from "react-toastify";
import { FrontEndRoutes } from "../../config/front-end-routes";
import { useNavigate } from "react-router-dom";
import { Card } from "primereact/card";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Footer } from '../../components/footer';
// Interface para propriedades do ChartContainer
interface ChartContainerProps {
    width: string;
}

// Estilização específica para o DashboardUser
const CustomDashContainer = styled(DashContainer)`
    display: flex;
    flex-direction: column;  // Padrão para visualização em dispositivos móveis
    align-items: center;
    justify-content: start;
    gap: 1rem;
    height: 100%; // Define a altura do container para cobrir toda a altura da tela

    @media (min-width: 768px) {  // Ajuste de acordo com as necessidades de breakpoint
        flex-direction: row;  // Layout em linha para telas maiores
        justify-content: space-between;  // Distribui espaço entre os itens
        align-items: stretch;  // Estica os itens para preencher o container verticalmente
    }
`;

// Estilizações para os containers dos gráficos
const ChartContainer = styled.div<ChartContainerProps>`
    width: 100%;  // Pega a largura total em dispositivos móveis
    
    margin-bottom: 1rem;  // Espaçamento entre os gráficos em dispositivos móveis

    @media (min-width: 768px) {
        width: ${props => props.width};  // Largura baseada na propriedade passada
        height: 80%;  // Altura para preencher o container pai
    }
`;


export const DashboardUser = () => {
    const [dataLines, setDataLines] = useState(null);
    const [dataPie, setDataPie] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const userToken = localStorage.getItem("token");
            if (userToken) {
                const parametros = { token: userToken };
                await api.post(BackEndRoutes.routes.dashboard.GERAL, parametros)
                    .then(response => {
                        setDataPie(response.data?.pie);
                        setDataLines(response.data?.medicoes);
                    })
                    .catch(error => {
                        toast.error("Sessão encerrada! Favor faça login novamente!");
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        navigate(FrontEndRoutes.LOGIN);
                    });
            }
        };

        fetchData();
    }, [navigate]);

    return (
        <StyleDashContainer>
            <Header />
            <CustomDashContainer>
                <StyleContainerDateInf>
                    <ChartContainer width="100%">
                        {!dataLines ?
                            <div className="card flex justify-content-center">
                                <ProgressSpinner />
                            </div> :
                            <LinesChart data={dataLines} />}
                    </ChartContainer>
                </StyleContainerDateInf>

                <StyleContainerPie>
                <ChartContainer width="100%">
                    {!dataPie ?
                        <div className="card flex justify-content-center">
                            <ProgressSpinner />
                        </div> :
                        <Card>
                            <PieChart data={dataPie} />
                        </Card>
                    }
                </ChartContainer>
                </StyleContainerPie>

            </CustomDashContainer>
           
            <Footer/>
        </StyleDashContainer>
    );
};
