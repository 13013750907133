import styled from "styled-components";

export const StyledContainerModal = styled.main`
    section{
        position: fixed;
        inset: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        top: 0;
        right: 0;
        z-index:9999;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    div{
        display: flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        gap:2rem;
        background: white;
        height: 150px;
        padding: 1rem;
        border-radius: 1rem;

        i{
            display:flex;
            gap: 4px;
        }
        button{
            width:100px;

        }
    }
`

  