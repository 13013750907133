import styled from 'styled-components';

export const StyledFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;

  label {
    display: flex;

    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-6);
    line-height: 19px;
    color: var(--color-gray-600);
  }

  input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    height: 50px;
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-6);
    line-height: 19px;
    
    color: var(--color-green-strong);
    background: var(--color-white);
    box-shadow: 0px 0px 8px 10px var(--color-green-shadow);
    border-radius: var(--border-radius-1);
    outline: 0;

    ::placeholder {
      font-weight: var(--font-weight-regular);
      font-size: var(--font-size-6);
      line-height: 19px;

      color: var(--color-gray-300);
    }

    :focus-within {
      border: 2px solid var(--color-green-strong);
      background-color: transparent;
      outline: var(--color-green-strong);
    }
  }
`;
