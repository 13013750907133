import { useEffect, useRef, useState } from 'react';
import {  StyleContHeaderOptions, StyleContainerDad, StyleFooterOptions, StyleNavContainerOption, StyledContainerModal } from './style';
import { Link } from 'react-router-dom';
import { StyledText } from '../../styles/typography';
import { FrontEndRoutes } from '../../config/front-end-routes';
import { BackEndRoutes } from '../../config/back-end-routes';
import { api } from '../../services/api';


export const ModalOptions = ({onClose}) => {

  const [showMeasurements, setShowMeasurements] = useState(false);
  const [showCorrections, setShowCorrections] = useState(false);
  const [userToken, ] = useState<any>(localStorage.getItem("token"));
  const modalRef = useRef<any>(null); 
  const [userID, setUserID] = useState<any>([])

  useEffect(() => {
      fetchData();
  }, [userToken]);

  const fetchData = async () => {
      if (userToken) {
          let parametro = { token: userToken };
          try {
              await api.post(BackEndRoutes.routes.auth.AUTH, parametro).then((response) => {
                  if (response.status === 200) {
                      setUserID(response.data);
                  }
              }).catch((error) => {
                  
                  if ( error?.response){
                      
                  }

              });

          } catch (error) {
              console.error("There was an error!", error);
          }
      } 
  };


  const handleMeasurements = () => {
    setShowMeasurements(!showMeasurements);
  };
  const handleCorrections = () => {
    setShowCorrections(!showCorrections);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
       
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <StyledContainerModal ref={modalRef}>
          <StyleContHeaderOptions>
            <div>
              <StyledText text="four" color="--color-white" lineHeight="24px" tag="p" className="">
                {userID?.name}
              </StyledText>
              <StyledText text="seven" color="--color-gray-100" lineHeight="24px" tag="p" className="">
                {userID?.email}
                </StyledText>
            </div>
            
          </StyleContHeaderOptions>
        <StyleContainerDad>
          <StyleNavContainerOption>
            <div>
              <i className="pi pi-home" style={{ fontSize: '1rem' }}></i>
              <Link to={FrontEndRoutes.AUTH.DASHBOARD.INDEX}>
                <StyledText text="six" color="--color-gray-300" lineHeight="24px" tag="p" className="">Home</StyledText>
              </Link>
            </div>
            <div>
              <i className="pi pi-file-edit" style={{ fontSize: '1rem' }}></i>
              <button onClick={handleMeasurements}>
                <StyledText text="six" color="--color-gray-300" lineHeight="24px" tag="p" className="">Medições</StyledText>
                <i className="pi pi-angle-down" style={{ fontSize: '1rem' }}></i>
              </button>
            </div>
            { showMeasurements && (
              <>
                <span>
                  <Link to={FrontEndRoutes.AUTH.MEASUREMENTS.INDEX}>
                    <StyledText text="six" color="--color-gray-300" lineHeight="24px" tag="p" className="">Lista de medições</StyledText>
                  </Link>
                  <Link to={FrontEndRoutes.AUTH.MEASUREMENTS.CREATE}>
                    <StyledText text="six" color="--color-gray-300" lineHeight="24px" tag="p" className="">Criar medição</StyledText>
                  </Link>
                </span>
              </>
            )}
            <div>
              <i className="pi pi-file-edit" style={{ fontSize: '1rem' }}></i>
              <button onClick={handleCorrections}>
                <StyledText text="six" color="--color-gray-300" lineHeight="24px" tag="p" className="">Correções</StyledText>
                  <i className="pi pi-angle-down" style={{ fontSize: '1rem' }}></i>
              </button>
            </div>
            { showCorrections && (
              <>
                <span>
                  <Link to={FrontEndRoutes.AUTH.CORRECTIONS.INDEX}>
                    <StyledText text="six" color="--color-gray-300" lineHeight="24px" tag="p" className="">Lista de correções</StyledText>
                  </Link>
                  <Link to={FrontEndRoutes.AUTH.CORRECTIONS.CREATE}>
                    <StyledText text="six" color="--color-gray-300" lineHeight="24px" tag="p" className="">Criar correção</StyledText>
                  </Link>
                </span>
              </>
            )}
          </StyleNavContainerOption>
        </StyleContainerDad> 
        <StyleFooterOptions>
          <StyledText text="six" color="--color-white" lineHeight="24px" tag="p" className=""> Diário da  Glicemia</StyledText>
        </StyleFooterOptions>      
    </StyledContainerModal>
  );
};
