import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

const LinesChart = ({ data }) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        // Processando os dados para o gráfico
        const labels = data.map(item => new Date(item.reference_date).toLocaleDateString());
        const measurements = data.map(item => item.measurement);

        const processedData = {
            labels: labels,
            datasets: [
                {
                    label: 'Medições',
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    yAxisID: 'y',
                    tension: 0.4,
                    data: measurements
                }
            ]
        };

        const options = {
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Data',
                        color: textColor
                    },
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Medição',
                        color: textColor
                    },
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            },
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            maintainAspectRatio: false,
            aspectRatio: 0.6
        };

        setChartData(processedData);
        setChartOptions(options);
    }, [data]);

    return (
        <div className="card">
            <Chart type="line" data={chartData} options={chartOptions} />
        </div>
    );
}

export default LinesChart;

