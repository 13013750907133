import { Route, Routes, Navigate } from "react-router-dom";
import { Home } from "../pages/home";
import { Login } from "../pages/login";

import { LandingPage } from "../pages/landing";
import { RegisterUser } from "../pages/register";
import { DashboardUser } from "../pages/dashboard";
import { RecoverPassword } from "../pages/recover-password";
import { PasswordReset } from "../pages/password-reset";
import { FrontEndRoutes } from "../config/front-end-routes";
import { Profile } from "../pages/profile";
import { Measure } from "../pages/measurements/measure";
import { Correction } from "../pages/corrections/correction";
import { AddCorrection } from "../pages/corrections/create-corrrection";
import { AddMeasure } from "../pages/measurements/create-measure";


export const RoutesMain = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to={FrontEndRoutes.DASHBOARD} />} />
      {/*<Route path={FrontEndRoutes.INDEX} element={<Home />} />*/}
      <Route path={FrontEndRoutes.LOGIN} element={<Login />} />
      <Route path={FrontEndRoutes.REGISTER} element={<RegisterUser />} />
      <Route path={FrontEndRoutes.LADING_PAGE} element={<LandingPage />} />
      <Route path={FrontEndRoutes.DASHBOARD} element={<DashboardUser />} />
      <Route path={FrontEndRoutes.RECOVER_PASSWORD} element={<RecoverPassword />} />
      <Route path={FrontEndRoutes.PASSWORD_RESET} element={<PasswordReset />} />
      <Route path={FrontEndRoutes.PROFILE} element={<Profile />} />
      <Route path={FrontEndRoutes.AUTH.MEASUREMENTS.INDEX} element={<Measure />} />
      <Route path={FrontEndRoutes.AUTH.MEASUREMENTS.CREATE} element={<AddMeasure />} />
      <Route path={FrontEndRoutes.AUTH.CORRECTIONS.INDEX} element={<Correction />} />
      <Route path={FrontEndRoutes.AUTH.CORRECTIONS.CREATE} element={<AddCorrection />} />
      <Route path={FrontEndRoutes.AUTH.MEASUREMENTS.EDIT} element={<AddMeasure/>} />
      <Route path={FrontEndRoutes.AUTH.CORRECTIONS.EDIT} element={<AddCorrection/>} />
    </Routes>
  );
};