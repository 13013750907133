import {UserProvider as Providers} from "./contexts/userContext/index"
import { RoutesMain } from "./routes";
import { Global } from "./styles/global";

function App() {
  return (
    <div>
      <Providers>
        <Global/>
        <RoutesMain/>
      </Providers>
    </div>
  );
}

export default App;
