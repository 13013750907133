import styled from "styled-components"

export const StyleContainerMasterResetPassword = styled.main`
    display:flex;
    flex-direction:column;
    gap:1rem;
    height:100vh;
    width:100vw;
  
`
export const StyledPasswordReset = styled.main`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
  
    padding: 8px;

    h2{
        color:var(--color-white)
    }
    
   
    form{
        display:flex;
        flex-direction:column;
        align-items:center;
        
        gap:32px;

        width:100%;
        & input{
            width:100%;
            height: 60px;

            border: solid 1px var(--color-green);
            border-radius:4px;

            padding-left:8px;
        }
        & label{
            color: var(--color-gray-300);

            align-items:flex-start;
        }
        & button{
            background-color: var(--color-green);
            color:var(--color-white);

            width:100%;
            height:50px;
            max-width:420px;

            border-radius:4px;
        }
        & span{
            font-size: var(--font-size-8);
            color: var(--color-error);
        }
      
    }
    @media(min-width: 821px){
        height:90vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
   
    }

`

export const Section = styled.section`
     display:flex;
     height:70vh;

     padding:16px;

    div{
        display:none;
    }

    img{
        height:90%;
        width:90%;
        max-width: 350px;
        max-height:320px;
    }
    div{
        background-color:rgba(154, 193, 217, 0.4);
        width:50%;  
    }
     
     @media(min-width: 821px){
        height:100%;
        padding:0;
        div{
            background-color:rgba(154, 193, 217, 0.4);
            
            width:50%; 

            display:flex;
            align-items:center;
            justify-content:center; 
        }
     }
`
export const SectionForm = styled.section`
    flex-direction:column;
    display:flex;
    align-items:center;
    justify-content:center;

    gap:48px;
    padding:1rem;

    max-width:500px;


    box-shadow: 0px 4px 4px var(--color-gray-600);
    border-radius: 8px 8px 4px 4px;
    div{
        display:flex;
        align-items:center;
        justify-content:center;
        background-color:var(--color-green);
        width:108%;
        max-width:inherit;
        height: 50px;
        margin-top: -11px;
        border-radius: 4px 4px 0px 0px;
        
    }
    section{
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;

        gap:16px;
        margin: 0 auto;
        
        & span{
            display:flex;
            align-items:center;
            justify-content: center;
            color: var(--color-blue-strong);
            gap:16px;
        }
    }
    
    @media(min-width: 821px){
        display:flex;
        align-items:center;
        justify-content:center;

        width:50%;
        form{
            display:flex;
            align-items:center;
            justify-content:center;
            

            width:100%;
            max-width:420px;
        }
        span{
            display:flex;
            align-items:flex-start;

            gap:16px;
        }
    }
`