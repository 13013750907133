import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserContext } from '../../../contexts/userContext';
import { Input } from '../../../components/input';
import { StyledButton } from '../../../components/button';
import { FrontEndRoutes } from '../../../config/front-end-routes';
import { Dropdown } from 'primereact/dropdown';
import { toast } from 'react-toastify';
import { GeralServices } from '../../../services/GeralServices';
import { Header } from '../../../components/header';
import { useNavigate, useParams } from 'react-router-dom';
import { BackEndRoutes } from '../../../config/back-end-routes';
import { api } from '../../../services/api';
import { useLocation } from 'react-router-dom';

import {
  StyleContainer,
  StyleForm,
  StyledTxtArea,
  StyledBoxButtons,
  StyledContainerDate,
  StyledContainerType,
  StyledConterTitleCreate,
  StyledContainerCreateCorrection,
  StyleContainerMaster,
} from './style';
import { iAddCorrection } from '../../../contexts/userContext/@types';
import { Footer } from '../../../components/footer';

export const AddCorrection = () => {

  const [selectedType, setSelectedType] = useState<any | null>(null);
  const [types, setTypes] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>(new Date().toISOString().split('T')[0]);
  const [selectedTime, setSelectedTime] = useState<any>(new Date().toLocaleTimeString('pt-BR').slice(0, 5));
  const [postContent, setPostContent] = useState('');
  const [editingCorrection, setEditingCorrection] = useState<any | null>(null);
  const [buscarDados, setBuscarDados] = useState(true);

  const location = useLocation();
  const isEditing = location.pathname.includes('/edit');
  const { id } = useParams<{ id: any }>();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTypes = () => {
      api.get(BackEndRoutes.routes.types)
        .then((response) => {
          if (response.status === 200) {
            setTypes(response.data?.correcoes);
            if (isEditing) {
              fetchCorrection();
            }
          }
        })
        .catch((error) => {
          toast.error('Algo deu errado ao buscar os tipos de correção...');
        });
    };

    fetchTypes();
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
  } = useForm<iAddCorrection>();

  const fetchCorrection = () => {
    if (!id || !buscarDados) {
      return;
    }
    api.get(BackEndRoutes.routes.corrections.SHOW.replace(':id', id))
      .then((response) => {
        if (response?.status === 200) {
          setBuscarDados(false);
          const correctionData = response?.data;
          setEditingCorrection(correctionData);
          popularCorrecao(correctionData);
        }
      })
      .catch((error) => {
        toast.error('Algo deu errado ao buscar a correção...');
      });
  };

  const popularCorrecao = (data) => {
    const { reference_date, correction_type, value, observation } = data;

    const datePart = reference_date.split(' ')[0];
    const timePart = reference_date.split(' ')[1].slice(0, 5);

    setSelectedDate(datePart);
    setSelectedTime(timePart);
    
     //= types.find(type => type.id === correction_type);
     const selectedTypeObject = types.find(type => type.id === correction_type);
    setSelectedType(selectedTypeObject);
    setValue("reference_date", datePart);
    setValue("correction_type", selectedTypeObject?.id); // Defina o ID do tipo
    setValue("value", value);
    setValue("observation", observation);
    setPostContent(observation);
  };

  const submit: SubmitHandler<any> = (formData) => {
    const dateTime = `${selectedDate} ${selectedTime}`;
    const typeID = selectedType ? selectedType.id : editingCorrection?.type_id;
    const postData = {
      ...formData,
      correction_id: editingCorrection?.id ?? null,
      correction_type: typeID,
      reference_date: dateTime ? GeralServices.formatDate(dateTime) : null,
      observation: postContent,
    };
  
    if (isEditing) {
      api.put(BackEndRoutes.routes.corrections.INDEX, postData)
        .then(() => {
          toast.success('Correção atualizada com sucesso!');
          navigate(FrontEndRoutes.AUTH.CORRECTIONS.INDEX);
        })
        .catch((error) => {
          console.error('Erro ao atualizar correção:', error);
          toast.error('Algo deu errado ao atualizar a correção...');
        });
    } else {
      api.post(BackEndRoutes.routes.corrections.INDEX, postData)
        .then(() => {
          toast.success('Correção criada com sucesso!');
          navigate(FrontEndRoutes.AUTH.CORRECTIONS.INDEX);
        })
        .catch((error) => {
          console.error('Erro ao salvar correção:', error);
          toast.error('Algo deu errado ao salvar a correção...');
        });
    }
  };

  const handleVoltar = () => {
    navigate(FrontEndRoutes.AUTH.CORRECTIONS.INDEX);
  };

  return (
    <StyleContainerMaster>
      <Header />
      <StyleContainer>
        <StyledContainerCreateCorrection>
          <StyledConterTitleCreate>
            <h2>{isEditing ? 'Edite a correção' : 'Crie uma nova correção'}</h2>
          </StyledConterTitleCreate>
          <StyleForm noValidate onSubmit={handleSubmit(submit)}>
            <StyledContainerDate>
              <Input
                type='date'
                id="reference_date"
                defaultValue={isEditing ? editingCorrection?.reference_date : selectedDate}
                {...register("reference_date")}
                onChange={date => setSelectedDate(date.target.value)}
              >
                Data
              </Input>
              <Input
                type='time'
                defaultValue={isEditing ? editingCorrection?.reference_date.split(' ')[1].slice(0, 5) : selectedTime}
                onChange={time => setSelectedTime(time.target.value)}
              >
                Hora
              </Input>
            </StyledContainerDate>
            <StyledContainerType>
              <p>Tipo da correção</p>
              <Dropdown
                value={isEditing ? types.find(type => type.id === editingCorrection?.type_id) : selectedType}
                id="correction_type"
                {...register("type_id")}
                onChange={isEditing ? (e) => {
                  setSelectedType(e.target.value)
                  editingCorrection.type_id = e.target.value?.id
                } : (e) => {setSelectedType(e.target.value)}}
                options={types}
                optionLabel="description"
                placeholder="Selecione o Tipo"
              />
            </StyledContainerType>
            <Input
              type="number"
              min={0}
              id="value"
              placeholder="Correção"
              defaultValue={isEditing ? editingCorrection?.value : ''}
              {...register("value")}
            >
              Correção
            </Input>
            <StyledTxtArea>
              Observação
              <textarea
                value={postContent}
                id='observation'
                {...register("observation")}
                name="postContent" rows={4}
                cols={100} placeholder='Digite uma observação'
                onChange={e => setPostContent(e.target.value)}
              />
            </StyledTxtArea>
            <StyledBoxButtons>
              <StyledButton type='button' buttonSize='small' buttonColor='gray' onClick={handleVoltar}>
                Voltar
              </StyledButton>
              <StyledButton type="submit" buttonSize="small" buttonColor="green">
                {isEditing ? 'Atualizar' : 'Gravar'}
              </StyledButton>
            </StyledBoxButtons>
          </StyleForm>
        </StyledContainerCreateCorrection>
      </StyleContainer>
      <Footer/>
    </StyleContainerMaster>
  );
};
