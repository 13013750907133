import styled, {css} from'styled-components'

interface iButtonProps{
  buttonSize: 'large'|'small';
  buttonColor: 'blue'|'green'|'gray'|'orange'|'purple';
}

export const StyledButton = styled.button<iButtonProps>`

font-size: var(--font-size-6) ;
font-weight: var(--font-weight-semibold);

border-radius: var(--border-radius-1);

transition: 0.5s;

color: var(--color-white);
opacity: 1;

  ${({ buttonSize }) => {
    switch (buttonSize) {
      case 'large':
        return css`
          padding: 20.5px 20px;
        `
      
      case 'small':
        return css`
          padding: 10.5px 20px;
        `
    }
  }}

${({ buttonColor }) => {
    switch (buttonColor) {
      case 'blue':
        return css`
          background-color: var(--color-blue-strong);

          &:hover{
            opacity: 0.8;
          }
        `
      case 'gray':
        return css`
          background-color: var(--color-gray-350);

          &:hover{
            opacity: 0.8;
          }
        `

      case 'green':
        return css`
          background-color: var(--color-green);

          &:hover{
            opacity: 0.8;
          }
        `
      case 'orange':
        return css`
          background-color: var(--color-orange);

          &:hover{
            opacity: 0.8;
          }
        `
      case 'purple':
        return css`
          background-color: var(--color-purple);

          &:hover{
            opacity: 0.8;
          }
        `      
    }
  }}
`