import { useState } from "react";
import CorrectionsComponent from "../list";
import { Header } from "../../../components/header";
import {  StyleContainerListTitle, StyleContainerMaster, StyleContainerReload, StyledCorrectionMain, StyledCorrectionPage, StyledNavAddRealod } from "./style";
import { Link } from "react-router-dom";
import { FrontEndRoutes } from "../../../config/front-end-routes";
import { Footer } from "../../../components/footer";


export const Correction = () => {

    const [reloadKey, setReloadKey] = useState(0);

    const recarregarComponente = () => {
        setReloadKey(prevKey => prevKey + 1);
    };
    return (
        <StyleContainerMaster>
            <Header />
            <StyledCorrectionMain>
                <StyledCorrectionPage>
                        <StyleContainerListTitle>
                            <span>
                                <h3>Lista de correção</h3>
                            </span>
                        <StyledNavAddRealod>
                            <StyleContainerReload>
                                <Link to={FrontEndRoutes.AUTH.CORRECTIONS.CREATE}>
                                    Adicionar &nbsp;
                                    <i className="pi pi-plus" style={{ fontSize: '1rem' }}></i>
                                </Link>
                            </StyleContainerReload>
                            <StyleContainerReload>
                                <button className="" onClick={recarregarComponente}>
                                    <i className="pi pi-sync" style={{ fontSize: '1rem' }}></i>
                                </button>
                            </StyleContainerReload>
                        </StyledNavAddRealod>
                    </StyleContainerListTitle>
                    <CorrectionsComponent key={reloadKey} />
                </StyledCorrectionPage>
            </StyledCorrectionMain>
            <Footer/>
        </StyleContainerMaster>
    );
}