import styled from "styled-components";
import { Link as LinkRouter } from "react-router-dom";
export const StyleContainer = styled.div`
  position: relative;
  max-width: 100%;
  padding: 0 5%;
  & img {
    width: 100%;
    max-width: 50%;
    min-width: 200px;
  }
  & figure {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 820px) {
    margin: 0 5%;
    width: 40%;
    img {
      display: none;
    }
  }
`;
export const StyleContainerDaddy = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 820px) {
    height: 100vh;
  }
`;
export const StyleContainerLogo = styled.div`
  display: none;
  @media (min-width: 820px) {
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-green);
    box-shadow: inset 5px 0px 15px var(--color-white);
    & img {
      width: 270px;
      border-radius: 999px;
    }
  }
`;
export const StyleHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  gap: 15px;
  margin-top: 10%;
  margin-bottom: 13%;
  & h2 {
    font-size: var(--font-size-3);
  }
  & p {
    font-size: var(--font-size-6);
    color: var(--color-gray-300);
  }
  @media (min-width: 820px) {
    display: flex;
    flex-direction:column;
    align-items:start;
    gap: 15px;
    margin-top: 10%;
    margin-bottom: 7%;
    
  }
`;
export const StyleForm = styled.form`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  
  input {
      border: none;
      background-color: transparent;
      border: 1px solid var(--color-green);
      padding: 1rem;
    &:focus {
      outline: none;
    }
    input:focus {
      outline: none;
    }
  }
  & button {
    margin-top: 5%;
    width: 782.21px;
    max-width: 100%;
  }
  button:hover {
    background-color: var(--color-green);
  }

  & span {
    font-size: var(--font-size-8);
    color: var(--color-error);
  }
  @media (min-width: 820px) {
    max-width: 420px;
  }
`;
export const StyleLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  & p {
    font-size: var(--font-size-6);
    color: var(--color-gray-300);
  }
  @media (min-width: 820px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    max-width: 420px;
  }
`;
export const Link = styled(LinkRouter)`
  font-size: var(--font-size-6);
  color: var(--color-blue-strong);
`;
export const IconAndHome = styled.div`
  & :nth-child(1) {
    display: none;
  }
  @media (min-width: 820px) {
    position: fixed;
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    & :nth-child(1) {
      display: block;
    }
  }
`;
export const LinkHome = styled(LinkRouter)`
  display: none;
  @media (min-width: 820px) {
    color: var(--color-gray-300);
    font-size: var(--font-size-6);
    display: block;
  }
`;
export const ContainerFooter = styled.div`
  @media (min-width: 820px) {
    display: none;
  }
`;
export const StyleDivPassword = styled.div`
  position: relative;
  display:flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  
  i{
    cursor: pointer;
    
    position:absolute;
    right:5%;
    bottom: 10px;
    
  }
`